export const redirectUri = encodeURI(
  `${window.location.protocol}//${window.location.host}/`
);

const {
  REACT_APP_AZURE_CLIENT_ID,
  REACT_APP_AZURE_AUTHORITY,
  REACT_APP_AZURE_ACCESS_AS_USER_API,
} = process.env;

export const msalConfig = {
  auth: {
    clientId: REACT_APP_AZURE_CLIENT_ID,
    authority: REACT_APP_AZURE_AUTHORITY,
    redirectUri,
    postLogoutRedirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
};
export const tokenRequest = {
  scopes: [REACT_APP_AZURE_ACCESS_AS_USER_API],
};
