export const translation = {
  en: {
    "invalid-token": "Invalid token",
    "no-habilitation-card": "No habilitation card",
    loading: "File Loading ...",
  },
  fr: {
    "invalid-token": "Token Invalide",
    "no-habilitation-card": "Aucune carte d'habilitation",
    loading: "Chargement du fichier...",
  },
};
