import React, { useState, useEffect } from "react";
import {  useMsal,useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest, tokenRequest } from "./authConfig";
import request from "superagent";
import {translation} from './translation/translations'
import {  Button } from 'antd';
import {DownloadOutlined, LoadingOutlined} from '@ant-design/icons';
const {REACT_APP_FILE_URL_JPG,REACT_APP_FILE_URL_PDF } = process.env;


const lang = /^fr\b/.test(navigator.language)? 'fr':'en'

const i18=(str)=>{
    return translation[lang][str]||str
}
function App() {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [accessToken, setAccessToken] = useState(null);
    const [blobcontent, setBlobcontent] = useState(null);
    const [blobError, setBlobError] = useState(null);
    const [jpgcontent, setJpgcontent] = useState(null);
    const [jpgError, setJpgError] = useState(null);
    // function lougout(){
    //     instance.logoutRedirect({
    //         postLogoutRedirectUri: "/",
    //     });
    // }

    function init(){
        instance.loginRedirect(loginRequest).catch(e => {
            console.error('ERROR',e);
        });
    }

    function accesToken(){
        const request = {
            ...tokenRequest,
            account: accounts[0]
        };
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.accessToken);
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                setAccessToken(response.accessToken);
            });
        });
    }

    useEffect(() => {
        if((inProgress === InteractionStatus.None) && !accounts.length){
            init()
        }else if((inProgress === InteractionStatus.None) && accounts.length){
            accesToken()
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProgress, accounts]);

    useEffect(() => {
        const getJpg = async (Authorization) => {
            const target = REACT_APP_FILE_URL_JPG;
            request
            .get(target).set({
                Authorization
            }).end(async function (err, res) {
              if (Array.isArray(res.body)){
               // const targetUrl =window.URL.createObjectURL(res.body)
                await getPdf()
                setJpgcontent (res.body);
                setJpgError(null)
              }else{

                setJpgcontent(null)
                setJpgError(i18(res.body.message))
              }
            });
         };
        if(accessToken){
            const Authorization =`Bearer ${accessToken}`;
            getJpg(Authorization)
        }
    }, [accessToken, accounts]);


    const getPdf = async () => {
        const Authorization =`Bearer ${accessToken}`;
        const target = REACT_APP_FILE_URL_PDF;
        request
        .get(target).responseType('blob').set({
            Authorization
        }).end(async function (err, res) {
            console.log(res.body.type)
          if (res.body.type==='application/pdf' ){
            const targetUrl =window.URL.createObjectURL(res.body)
            setBlobcontent(targetUrl);
            setBlobError(null)
          }else{
            const txt =await  res.body.text()
            setBlobcontent(null)
            setBlobError(i18(JSON.parse(txt).message))
          }
        });
     };
    return (
        <>
            <div className="header">
                <div className="account">Habilitations  :  {accounts.length ?accounts[0].name:null}</div>
            </div>
            <div className='container'>
                {!isAuthenticated && <div className="loading">...</div>}
                
                {isAuthenticated && !jpgcontent && !jpgError  && 
                <div className="loading">  <LoadingOutlined style={{color : '#1677ff'}}/> {i18('loading')}</div>
                }
               
                {isAuthenticated && jpgError  &&  <div className="error">{jpgError}</div>}
                
                {isAuthenticated && !jpgError && jpgcontent && 
                jpgcontent.map(jpg=><div className = 'imgHabilitation'><img width={'100%'} src={`data:image/jpg;base64,${jpg}`}/></div>)
                } 
               
            </div>

            {isAuthenticated && blobcontent && !blobError &&
           
              <Button 
                    href={blobcontent} 
                    download={`Habilitation-${accounts[0].name}.pdf`}
                    className={'floatingBtn'}
                    type="primary" 
                    icon={<DownloadOutlined />} 
                    shape="square"
                    size='large'/>
                
          
            }

        </>
    );
  }
export default App;